export const theme = {
  colors: {
    blue: {
      base: '#3eb0ef',
      bold: '#26a8ed',
      bolder: '#107ebb',
      light: '#cbeafb',
    },
    green: {
      base: '#a4d037',
    },
    purple: {
      base: '#ad26b4',
    },
    yellow: {
      base: '#fecd35',
    },
    red: {
      base: '#f05230',
    },
    darkgrey: {
      base: '#15171a',
      bold: '#0e0f11',
      bolder: '#090a0b',
      light: '#2b2f36',
      lighter: '#424852',
    },
    midgrey: {
      base: '#738a94',
      bold: '#3c484e',
      bolder: '#313b3f',
      light: '#92a3ab',
    },
    lightgrey: {
      base: '#c5d2d9',
      light: '#e3e9ed',
      lighter: '#e9eef1',
    },
    whitegrey: {
      base: '#e5eff5',
      bold: '#e3ecf3',
      light: '#f4f8fb',
    },
    pink: {
      base: '#fa3a57',
    },
    brown: {
      base: '#a3821a',
    },
  },
  breakpoints: {
    xs: '575.98px',
    sm: '767.98px',
    md: '991.98px',
    lg: '1199.98px',
  },
}
